import {
	SET_REGION,
	SET_REGIONS,
	SET_CURRENT_COUNTRY,
	SET_COUNTRIES,
	SET_COUNTRY
} from '../actionTypes';
import Common from '../../src/services/common';

export const getAllCountries = (data) => {
	return async (dispatch) => {
		return Common.get_countries(data)
			.then(async (r) => {
				await dispatch({
					type: SET_COUNTRIES,
					payload: r.data.countries
				});
				return { spinnerScreen: false };
			})
			.catch((e) => {
				return {
					spinnerScreen: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al obtener los paises, intente nuevamente.'
				};
			});
	};
};

export const massiveLoadCountries = (data) => {
	return async (dispatch) => {
		return Common.massive_load_countries(data)
			.then((res) => {
				const { registered_countries, tried_countries, invalid_countries } =
					res.data;
				return {
					registered_countries,
					tried_countries,
					invalid_countries,
					success: true,
					spinnerScreen: false,
					showDialogMassive: false,
					showDialogSuccess: true
					// openAlert: true,
					// messageAlert: `Paises cargados ${registered_countries} de ${tried_countries}`,
				};
			})
			.catch((e) => {
				return {
					spinnerScreen: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al registrar los paises, intente nuevamente.'
				};
			});
	};
};

export const createCountry = (data) => {
	return async (dispatch) => {
		return Common.create_country(data)
			.then(() => {
				return {
					success: true,
					spinnerScreen: false,
					showDialogCountry: false,
					openAlert: true,
					messageAlert: 'Pais creado exitosamente.'
				};
			})
			.catch((e) => {
				return {
					spinnerScreen: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al crear el pais, intente nuevamente.'
				};
			});
	};
};

export const updateCountry = (id, data) => {
	return async (dispatch) => {
		return Common.update_country(id, data)
			.then(() => {
				return {
					success: true,
					spinnerScreen: false,
					showDialogCountry: false,
					openAlert: true,
					messageAlert: 'Pais actualizado exitosamente.'
				};
			})
			.catch((e) => {
				return {
					spinnerScreen: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al actualizar el pais, intente nuevamente.'
				};
			});
	};
};

export const deleteCountry = (data) => {
	return async (dispatch) => {
		return Common.delete_country(data)
			.then(() => {
				return {
					success: true,
					spinnerScreen: false,
					showConfirm: false,
					openAlert: true,
					messageAlert: 'Pais eliminado exitosamente.'
				};
			})
			.catch((e) => {
				return {
					spinnerScreen: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al eliminar el pais, intente nuevamente.'
				};
			});
	};
};

export const getAllRegions = () => {
	return async (dispatch) => {
		return Common.get_regions()
			.then(async (r) => {
				await dispatch({
					type: SET_REGIONS,
					payload: r.data.regions
				});
				return { spinnerScreen: false };
			})
			.catch((e) => {
				return {
					spinnerScreen: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al obtener las regiones, intente nuevamente.'
				};
			});
	};
};

export const createRegion = (data) => {
	return async (dispatch) => {
		return Common.create_region(data)
			.then(() => {
				return {
					success: true,
					spinnerScreen: false,
					showDialog: false,
					openAlert: true,
					messageAlert: 'Nueva región registrado exitosamente.'
				};
			})
			.catch((e) => {
				return {
					spinnerScreen: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al crear la región, intente nuevamente.'
				};
			});
	};
};

export const updateRegion = (id, data) => {
	return async (dispatch) => {
		return Common.update_region(id, data)
			.then(() => {
				return {
					success: true,
					spinnerScreen: false,
					showDialog: false,
					openAlert: true,
					messageAlert: 'Región actualizada exitosamente.'
				};
			})
			.catch((e) => {
				return {
					spinnerScreen: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al actualizar la región, intente nuevamente.'
				};
			});
	};
};

export const setCurrentCountry = (country) => async (dispatch) => {
	return await dispatch({
		type: SET_CURRENT_COUNTRY,
		payload: country
	});
};

export const setRegionData = (data) => async (dispatch) => {
	return await dispatch({
		type: SET_REGION,
		payload: data
	});
};

export const setCountryData = (data) => async (dispatch) => {
	return await dispatch({
		type: SET_COUNTRY,
		payload: data
	});
};
