import {
	SAVE_OVERDUE_PAYMENTS,
	SAVE_TDC_PAYMENTS,
	SAVE_PENDING_PAYMENTS
} from '../actionTypes';
import Common from '../../src/services/common.js';

const FileDownload = require('js-file-download');

export const updatePaymentFees = (values) => {
	return async (dispatch) => {
		return await Common.update_payment_fees(values)
			.then(async (r) => {
				return {
					success: true,
					openAlert: true,
					messageAlert: r.data?.new_fees?.message,
					spinnerScreen: false
				};
			})
			.catch((e) => {
				return {
					spinnerScreen: false,
					openAlert: true,
					messageAlert: e?.response?.data
						? e?.response?.data?.error?.message
						: 'Hubo un error al consultar sus datos, intente nuevamente.'
				};
			});
	};
};

export function getFilePayment(value) {
	const name = value.split('/');
	return async () => {
		return await Common.getFilePayment(
			{ responseType: 'blob' },
			{ fileName: value }
		)
			.then(async (res) => {
				await FileDownload(res.data, name.pop());
				return {
					spinnerLink: false,
					spinnerScreen: false
				};
			})
			.catch((e) => {
				return {
					spinnerScreen: false,
					spinnerLink: false,
					openAlert: true,
					messageAlert:
						'Hubo un error al descargar el archivo, intente nuevamente.'
				};
			});
	};
}

export function getTdcPayments(skip = 0, limit = 5) {
	return async (dispatch) => {
		return await Common.get_tdc_payments({ skip, limit })
			.then(async (res) => {
				await dispatch({
					type: SAVE_TDC_PAYMENTS,
					payload: {
						values: {
							data: res.data.docs,
							isSearch: false
						}
					}
				});
				let result = {
					spinnerScreen: false
				};
				return result;
			})
			.catch((e) => {
				return {
					spinnerScreen: false,
					openAlert: true,
					messageAlert: e?.response?.data?.error?.message
						? e.response.data.error.message
						: 'Hubo un error al procesar su solicitud, intente nuevamente.'
				};
			});
	};
}

export function getOverduePayments(skip = 0, limit = 5) {
	return async (dispatch) => {
		return await Common.get_overdue_payments({ skip, limit })
			.then(async (res) => {
				await dispatch({
					type: SAVE_OVERDUE_PAYMENTS,
					payload: {
						values: {
							data: res.data.docs,
							isSearch: false
						}
					}
				});
				let result = {
					spinnerScreen: false
				};
				return result;
			})
			.catch((e) => {
				return {
					spinnerScreen: false,
					openAlert: true,
					messageAlert: e?.response?.data?.error?.message
						? e.response.data.error.message
						: 'Hubo un error al procesar su solicitud, intente nuevamente.'
				};
			});
	};
}

export function getPendingPayments(skip = 0, limit = 5) {
	return async (dispatch) => {
		return await Common.get_pending_payments({ skip, limit })
			.then(async (res) => {
				await dispatch({
					type: SAVE_PENDING_PAYMENTS,
					payload: {
						values: {
							data: res.data.docs,
							isSearch: false
						}
					}
				});
				let result = {
					spinnerScreen: false
				};
				return result;
			})
			.catch((e) => {
				return {
					spinnerScreen: false,
					openAlert: true,
					messageAlert: e?.response?.data?.error?.message
						? e.response.data.error.message
						: 'Hubo un error al procesar su solicitud, intente nuevamente.'
				};
			});
	};
}

export function sendAcunsaCode(data) {
	return async (dispatch) => {
		return await Common.send_acunsa_code(data)
			.then(async (res) => {
				return {
					messageAlert: res.data.message
						? res.data.message
						: '¡Has aceptado los términos y condiciones con éxito!',
					openAlert: true,
					showSpinner: false,
					success: true
				};
			})
			.catch((e) => {
				return {
					showSpinner: false,
					openAlert: true,
					messageAlert: e?.response?.data?.error?.message
						? e.response.data.error.message
						: 'Hubo un error al procesar su solicitud, intente nuevamente.'
				};
			});
	};
}
