export class Beneficiary {
	constructor(data) {
		this._id = data._id || '';
		this.first_name = data.first_name || '';
		this.last_name = data.last_name || '';
		this.nationality = data.nationality || 'default';
		this.dni = data.dni || '';
		this.sex = data.sex || 'default';
		this.email = data.email || '';
		this.phone_one = data.phone_one || '';
		this.phone_two = data.phone_two || '';
		this.state = data.state || '';
		this.country = data.country || 'default';
		this.city = data.city || 'default';
		this.address = data.address || '';
		this.birth_date = data.birth_date || null;
		this.age = data.age || null;
		this.weight = data.weight || '';
		this.feet = data.feet || '';
		this.inches = data.inches || '';
		this.body_mass = data.body_mass || 0;
		this.kinship = data.kinship || 'default';
		this.other_kinship = data.other_kinship || '';
		this.occupation = data.occupation || '';
		this.city_two = data.city_two || '';
		this.insurance_company = data.insurance_company || '';
		this.medicines = data.medicines || false;
		this.medicines_price = data.medicines_price || 0;
		this.funeral_service = data.funeral_service || false;
		this.funeral_service_price = data.funeral_service_price || 0;
		this.funeral_service_coverage = data.funeral_service_coverage || 0;
		this.id_user_creator = data.id_user_creator;
		this.products = data.products || [];
		this.product_id = data.product_id || '';
		this.product_type = data.product_type || {};
		this.product_price = data.product_price || 0;
		this.questions = data.questions || [];
		this.answers = data.answers || [];
		this.pending_validation = data.pending_validation || '';
		this.conditions_pregnant = data.conditions_pregnant || false;
		this.coupon = data.coupon || '';
		this.formErrors = data.formErrors || [];
		this.total = data.total || 0;
		this.addons = data.addons || [];
		this.show_warning = data.addons || false;
		this.complete_surveys = data.addons || false;
		this.beneficiary_sha = data.beneficiary_sha || null;
		this.NotEditCustomer = data.NotEditCustomer;
		this.medicalVerification = data.medicalVerification;
	}
}
