import {
	CUSTOMER_VALIDATION,
	SELECT_CAMPAIGN,
	SELECT_PLAN,
	CHECKED_TERMS,
	CHANGE_VALUE_INPUT_AFFILIATION,
	CHANGE_STATE_AFFILIATION,
	CALCULATE_TOTAL_PAYMENT,
	CLEAN_AFFILIATION,
	CLEAN_AFFILIATION_ADMIN,
	CLEAN_FORM_ERRORS_BENEFICIARIES,
	SAVE_TOKEN,
	USE_TITULAR_DATA,
	ALL_PRODUCTS,
	SAVE_USER_TYPE,
	SAVE_TITULAR_EMAIL,
	SHOW_DISCOUNT_PERCENT,
	SET_IS_CLIENT,
	SAVE_ALLY_NAME,
	UPDATE_TITULAR_DATA,
	SAVE_USERID,
	SAVE_MOBILE_APPS_LINKS,
} from '../actionTypes';
import Common from '../../src/services/common.js';
import Validations from '../../src/components/utils/Validations';
import { getHash } from '../../src/components/utils/Hash';

export const customerValidation = (values) => {
	return async (dispatch) => {
		return await Common.affiliations({ ...values })
			.then(async (res) => {
				await dispatch({
					type: CUSTOMER_VALIDATION,
					payload: {
						values: {
							data: {
								...res.data,
								init_affiliation: true
							}
						}
					}
				});
				let result = {
					showLoadding: false,
					openCampaignDialog: true,
					redirectToAfiliate:
						res.data && res.data.is_saved === true ? true : false
				};
				return result;
			})
			.catch(async (err) => {
				return {
					showLoadding: false,
					openAlert: true,
					messageAlert:
						err.response && err.response.data
							? err.response.data.error.message
							: 'Hubo un error al validar el usuario, intente nuevamente',
					continue: false
				};
			});
	};
};

export const handleInputInitAffiliation =
	(nameInput, valueInput) => (dispatch) =>
		dispatch({
			type: CHANGE_VALUE_INPUT_AFFILIATION,
			payload: {
				values: {
					data: {
						nameInput,
						valueInput
					}
				}
			}
		});

export const validateFieldInitAffiliation = (formError, name, value) => {
	const formErrors = Validations.validFieldInitAffiliation(
		formError,
		name,
		value
	);

	return (dispatch) =>
		dispatch({
			type: CHANGE_VALUE_INPUT_AFFILIATION,
			payload: {
				values: {
					data: {
						name: 'formErrors',
						value: formErrors
					}
				}
			}
		});
};

export const handleSaveProducts = (values) => {
	return async (dispatch) =>
		await dispatch({
			type: ALL_PRODUCTS,
			payload: {
				values
			}
		});
};

export const handleSelectCampaign = (
	campaign_id,
	campaign_name,
	company_id,
	company_name,
	plans
) => {
	return async (dispatch) =>
		await dispatch({
			type: SELECT_CAMPAIGN,
			payload: {
				values: {
					data: {
						campaign_id,
						campaign_name,
						company_id,
						company_name,
						plans
					}
				}
			}
		});
};

export const handleSelectPlan = (product_type, index) => async (dispatch) => {
	return await dispatch({
		type: SELECT_PLAN,
		payload: {
			values: {
				data: {
					product_type,
					index
				}
			}
		}
	});
};

export const handleCheckTerms = (value) => {
	return async (dispatch) =>
		await dispatch({
			type: CHECKED_TERMS,
			payload: {
				values: {
					data: {
						terms: value
					}
				}
			}
		});
};

export const handleStateAffiliation = (name, value) => {
	return async (dispatch) => {
		return await dispatch({
			type: CHANGE_STATE_AFFILIATION,
			payload: {
				values: {
					data: {
						name,
						value
					}
				}
			}
		});
	};
};

export const saveDataCustomer = (values) => {
	if (values.titular && values.titular.include_titular === true) {
		values.titular.kinship = 'TITULAR';
		values.titular.feet = parseFloat(values.titular.feet);
		values.titular.inches = parseFloat(values.titular.inches);
		values.titular.weight = parseInt(values.titular.weight);
		values.titular.age = parseInt(values.titular.age);
		values.titular.body_mass = parseInt(values.titular.body_mass);
		if (!values.titular.beneficiary_sha)
			values.titular.beneficiary_sha = getHash();
	}

	if (values.beneficiaries) {
		values.beneficiaries.map((beneficiary) => {
			beneficiary.feet = parseFloat(beneficiary.feet);
			beneficiary.inches = parseFloat(beneficiary.inches);
			beneficiary.weight = parseInt(beneficiary.weight);
			beneficiary.age = parseInt(beneficiary.age);
			beneficiary.body_mass = parseInt(beneficiary.body_mass);
			if (!beneficiary.beneficiary_sha) beneficiary.beneficiary_sha = getHash();
			return beneficiary;
		});
	}

	return async (dispatch) => {
		return await Common.save_data_customer(values)
			.then((res) => {
				let beneficiaries = res?.data?.beneficiaries;
				beneficiaries.map((b) => (b.formErrors = []));
				handleStateAffiliation('beneficiaries', beneficiaries);
				if (res?.data?.titular && res.data.titular.include_titular === true)
					handleStateAffiliation('titular', res?.data?.titular);
				return {
					beneficiaries: beneficiaries || values?.beneficiaries,
					titular: res?.data?.titular || values?.titular,
					savedSuccess: res.data.saved,
					savingMessage: false,
					success: true
				};
			})
			.catch((e) => {
				if (
					e &&
					e.response &&
					(e.response.status === 401 || e.response.status === 403)
				) {
					return {
						showRegistryDialog: true,
						savingMessage: false,
						openAlert: true,
						messageAlert:
							e.response && e.response.data
								? e.response.data.error.message
								: 'Hubo un error al guardar su información, intente nuevamente.',
						success: false
					};
				} else {
					return {
						showRegistryDialog: false,
						savingMessage: false,
						openAlert: true,
						duplicatedError: e.response?.data?.duplicatedError,
						messageAlert:
							e.response && e.response.data
								? e.response.data.error.message
								: 'Hubo un error al guardar su información, intente nuevamente.',
						success: false
					};
				}
			});
	};
};

export const get_payment_methods = (values) => {
	return async (dispatch) => {
		return await Common.get_payment_methods(values)
			.then((r) => {
				return {
					success: true,
					paymentMethods: r.data.payment_methods,
					promotion: r.data.promotion,
					savingMessage: false
				};
			})
			.catch((e) => {
				return {
					savingMessage: false,
					success: false,
					openAlert: true,
					messageAlert: e?.response?.data?.error?.message
						? e.response.data.error.message
						: 'Hubo un error al consultar los metodos de pago, intente nuevamente.'
				};
			});
	};
};

export const save_token = (values) => async (dispatch) =>
	await dispatch({
		type: SAVE_TOKEN,
		payload: {
			values
		}
	});

export const save_user_type = (values) => async (dispatch) =>
	await dispatch({
		type: SAVE_USER_TYPE,
		payload: {
			values
		}
	});

export const save_titular_email = (values) => async (dispatch) =>
	await dispatch({
		type: SAVE_TITULAR_EMAIL,
		payload: {
			values
		}
	});

export const calculateTotalPayment = (values) => {
	return async (dispatch) =>
		await dispatch({
			type: CALCULATE_TOTAL_PAYMENT,
			payload: {
				values
			}
		});
};

export const useTitularData = (values) => {
	return async (dispatch) =>
		await dispatch({
			type: USE_TITULAR_DATA,
			payload: {
				values
			}
		});
};

export const cleanAffiliation = () => async (dispatch) =>
	await dispatch({ type: CLEAN_AFFILIATION, payload: {} });
export const cleanAffiliationAdmin = () => async (dispatch) =>
	await dispatch({ type: CLEAN_AFFILIATION_ADMIN, payload: {} });

export const sign_up = (values) => {
	return async () => {
		return await Common.sign_up_social(values)
			.then((res) => {
				return {
					savedSuccess: res.data.saved,
					messageAlert: 'Cliente afiliado con exito.',
					savingMessage: false,
					idTitular: res.data._id,
					success: true,
					coupon: res.data.coupon,
					percent_off: res.data.percent_off,
					free_trial: res.data.free_trial
				};
			})
			.catch((e) => {
				if (
					e.response &&
					(e.response.status === 401 || e.response.status === 403)
				) {
					return {
						showRegistryDialog: true,
						savingMessage: false,
						openAlert: true,
						messageAlert:
							e.response && e.response.data
								? e.response.data.error.message
								: 'Hubo un error al guardar su información, intente nuevamente.',
						success: false
					};
				} else {
					return {
						showRegistryDialog: false,
						savingMessage: false,
						openAlert: true,
						messageAlert:
							e.response && e.response.data
								? e.response.data.error.message
								: 'Hubo un error al guardar su información, intente nuevamente.',
						success: false
					};
				}
			});
	};
};

export const getProducts = (values) => {
	return async (dispatch) => {
		return await Common.get_plans_by_country(values)
			.then(async (r) => {
				await dispatch({
					type: ALL_PRODUCTS,
					payload: {
						values: r.data.products
					}
				});
				let result = {
					success: true,
					showLoadding: false,
					products: r.data.products,
					options: r.data.options,
					special_services: r.data.special_services,
					payment_methods: r.data.payment_methods
				};
				return result;
			})
			.catch((e) => {
				return {
					success: false,
					showLoadding: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al consultar sus datos, intente nuevamente.'
				};
			});
	};
};

export const productsQuotation = (values) => {
	return async (dispatch) => {
		return await Common.products_quotation(values)
			.then(async (r) => {
				return {
					intervals: r.data,
					percent_off: r.data.percent_off,
					success: true
				};
			})
			.catch((e) => {
				return {
					success: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al solicitar la información, intente nuevamente.'
				};
			});
	};
};

export const sharedQuotation = (values) => {
	return async (dispatch) => {
		return await Common.shared_quotation(values)
			.then(async (r) => {
				return {
					openAlert: true,
					messageAlert: r.data.message,
					success: true
				};
			})
			.catch((e) => {
				return {
					success: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al enviar la cotización, intente nuevamente.'
				};
			});
	};
};

export const getPlansByAge = (values) => {
	return async (dispatch) => {
		return await Common.get_plans_by_age(values)
			.then(async (r) => {
				return {
					success: true,
					products: r.data.products
				};
			})
			.catch((e) => {
				return {
					success: false,
					openAlert: true,
					messageAlert:
						e.response && e.response.data
							? e.response.data.error.message
							: 'Hubo un error al solicitar sus planes, intente nuevamente.'
				};
			});
	};
};

export const saveDiscountPercent = (values) => {
	return async (dispatch) =>
		await dispatch({
			type: SHOW_DISCOUNT_PERCENT,
			payload: {
				values
			}
		});
};

export const validateCoupon = (values) => {
	return async (dispatch) => {
		return await Common.validate_coupon(values)
			.then(async (res) => {
				await dispatch({
					type: SHOW_DISCOUNT_PERCENT,
					payload: {
						values: {
							percent_off: res.data.percent_off,
							coupon: res.data.coupon,
							free_trial: res.data.free_trial
						}
					}
				});
				let result = { success: true };
				return result;
			})
			.catch(async (err) => {
				return {
					success: false,
					openAlert: true,
					messageAlert:
						err.response && err.response.data
							? err.response.data.error.message
							: 'Hubo un error al validar el cupón, intente nuevamente'
				};
			});
	};
};

export const paymentAffiliation = (values) => {
	return async (dispatch) => {
		return await Common.payment_affiliation(values)
			.then((res) => {
				return {
					success: true,
					spinnerProgress: false,
					statusPayment: res.data.statusPayment,
					paymentResponse: res.data.paymentResponse,
					paymentSubResponse: res.data.paymentSubResponse
				};
			})
			.catch((e) => {
				return {
					success: false,
					spinnerProgress: false,
					statusPayment: false,
					paymentResponse: e?.response?.data?.error?.message
						? e.response.data.error.message
						: 'Hubo un error al procesar su pago, intente nuevamente.',
					messageAlert: e?.response?.data?.error?.message
						? e.response.data.error.message
						: 'Hubo un error al procesar su pago, intente nuevamente.',
					openAlert: true
				};
			});
	};
};

export const resendInvitationAlly = (value) => {
	return async (dispatch) => {
		return await Common.resend_email_ally(value)
			.then((res) => {
				return {
					messageAlert: 'Invitación enviada con éxito',
					openAlert: true
				};
			})
			.catch((e) => {
				return {
					messageAlert:
						'Hubo un error al enviar la invitación, intente nuevamente.',
					openAlert: true
				};
			});
	};
};

export const clearFormErrorsBeneficiaries = () => async (dispatch) =>
	await dispatch({ type: CLEAN_FORM_ERRORS_BENEFICIARIES, payload: [] });

export const assignAddonToBeneficiary = (values) => async (dispatch) => {
	return await Common.assignAddon(values)
		.then((res) => {
			return {
				success: true,
				customer: res.data.customer,
				payment_methods: res.data.payment_methods,
				showSpinner: false,
				openAlert: true,
				messageAlert: res.data?.message ?? 'Asignado con éxito.'
			};
		})
		.catch((e) => {
			return {
				success: false,
				showSpinner: false,
				openAlert: true,
				messageAlert: e?.response?.data?.error?.message
					? e.response.data.error.message
					: 'Hubo un error al eliminar al beneficiario, intente nuevamente.'
			};
		});
};

// REMOVE TEMP BENEFICIARIES
export const removeTempBeneficiaries = (beneficiary_sha) => {
	return async (dispatch) => {
		return await Common.remove_temp_beneficiaries({ beneficiary_sha })
			.then(async (response) => {
				return {
					loading: false,
					openAlert: true,
					messageAlert: response?.data?.message
				};
			})
			.catch((e) => {
				return {
					loading: false,
					openAlert: true,
					messageAlert: e?.response?.data?.error?.message
						? e.response.data.error.message
						: 'Hubo un error al eliminar al beneficiario, intente nuevamente.'
				};
			});
	};
};

export const save_is_client = (values) => {
	return async (dispatch) =>
		await dispatch({
			type: SET_IS_CLIENT,
			payload: {
				values
			}
		});
};

export const save_ally_name = (values) => async (dispatch) =>
	await dispatch({
		type: SAVE_ALLY_NAME,
		payload: {
			values
		}
	});

//Accept Terms ADVISER
export const acceptTermsAdviser = () => {
	return async (dispatch) => {
		return await Common.accept_terms_conditions()
			.then(async (response) => {
				return {
					success: true
				};
			})
			.catch((e) => {
				return {
					loading: false,
					openAlert: true,
					messageAlert: e?.response?.data?.error?.message
						? e.response.data.error.message
						: 'Hubo un error al aceptar su declaración.'
				};
			});
	};
};

export const callAdviser = (values) => {
	return async (dispatch) => {
		return await Common.notify_adviser(values)
			.then(async (response) => {
				return {
					success: true,
					openAlert: true,
					showLoading: false,
					messageAlert: 'El Asesor se contactará con usted'
				};
			})
			.catch((e) => {
				return {
					showLoading: false,
					openAlert: true,
					messageAlert: e?.response?.data?.error?.message
						? e.response.data.error.message
						: 'Hubo un error al contactar al Asesor.'
				};
			});
	};
};

// reload titular data
export const updateReloadTitularData = (values) => async (dispatch) =>
	await dispatch({
		type: UPDATE_TITULAR_DATA,
		payload: {
			values
		}
	});

export const save_userID = (values) => async (dispatch) =>
	await dispatch({
		type: SAVE_USERID,
		payload: {
			values
		}
	});

export function getConfig() {
	return async (dispatch) => {
		return Common.country_config()
			.then(async (res) => {
				await dispatch({
					type: SAVE_MOBILE_APPS_LINKS,
					payload: res.data?.mobileAppLinks
				});

				return {
					success: true,
					mobileAppLinks: res.data?.mobileAppLinks
				};
			})
			.catch((e) => {
				return {
					success: false,
					spinnerScreen: false,
					openAlert: true,
					messageAlert: e?.response?.data?.error?.message
						? e.response.data.error.message
						: 'An error occurred while obtaining the configuration.'
				};
			});
	};
}
