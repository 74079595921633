import React, { Component } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker
} from '@material-ui/pickers';
import esLocale from 'date-fns/locale/es';
import 'date-fns';

class DateInput extends Component {
	bothInputProps = (value, error, readOnly) => ({
		InputLabelProps: {
			classes: {
				root:
					value && !error
						? 'cssLabel'
						: (!value && error) || error
						? 'cssLabelError'
						: 'cssLabelSuccess'
			},
			shrink: true
		},
		InputProps: {
			classes: {
				notchedOutline:
					value && !error
						? 'cssInput'
						: (!value && error) || error
						? 'cssInputError'
						: 'cssInputSuccess'
			},
			readOnly
		}
	});

	render() {
		const {
			name,
			value,
			errors,
			onChange,
			error = true,
			required = true,
			label = '',
			addClass = '',
			helperText = '',
			placeholder = '',
			margin = 'normal',
			variant = 'outlined',
			// openTo = 'year',
			// views = ["month", "year", "day"],
			maxDate,
			minDate,
			labelClass = '',
			clearLabel = 'Clear',
			onClear,
			disabled = false,
			readOnly = false
		} = this.props;

		return (
			<MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
				<KeyboardDatePicker
					margin={margin}
					id={label}
					label={label}
					format="dd/MM/yyyy"
					value={value}
					onChange={onChange}
					KeyboardButtonProps={{ 'aria-label': { name } }}
					error={error}
					{...(label && {
						label: <span className={labelClass}>{label}</span>
					})}
					placeholder={placeholder}
					required={required}
					// format={format}
					helperText={helperText}
					maxDate={maxDate && maxDate}
					minDate={minDate && minDate}
					inputVariant={variant}
					className={addClass}
					// openTo={openTo}
					// views={views}
					clearLabel={clearLabel}
					{...this.bothInputProps(value, errors, readOnly)}
					{...(onClear && { onClear: onClear })}
					disabled={disabled}
				/>
			</MuiPickersUtilsProvider>
		);
	}
}

export default DateInput;
