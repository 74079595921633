import {
	RESET_MANAGED_USER,
	UPDATE_MANAGED_USER,
	SAVE_ASISTENSI_COUNTRIES
} from '../actionTypes';
import Common from '../../src/services/common.js';

export const createUser = (user) => {
	return async (dispatch) => {
		return await Common.create_user(user)
			.then(async () => {
				return {
					data: {
						openAlert: true,
						messageAlert: 'El usuario ha sido creado exitosamente',
						showDialog: false
					},
					success: true
				};
			})
			.catch((e) => {
				return {
					data: {
						openAlert: true,
						messageAlert:
							e.response && e.response.data
								? e.response.data.error.message
								: 'Hubo un error al crear el usuario, intente nuevamente.'
					},
					success: false
				};
			});
	};
};

export const updateUser = (user) => {
	return async (dispatch) => {
		return await Common.user_update(user)
			.then(async () => {
				return {
					data: {
						showDialog: false,
						openAlert: true,
						messageAlert: 'El usuario ha sido actualizado exitosamente.'
					},
					success: true
				};
			})
			.catch((e) => {
				return {
					data: {
						openAlert: true,
						messageAlert:
							e.response && e.response.data
								? e.response.data.error.message
								: 'Ha ocurrido un error al actualizar la información, intente nuevamente.'
					},
					success: false
				};
			});
	};
};

export const deleteUser = (userId) => {
	return async (dispatch) => {
		return await Common.delete_user({ _id: userId })
			.then(async (update) => {
				return {
					data: {
						...update,
						showConfirm: false,
						spinnerButton: false,
						propsConfirm: null,
						openAlert: true,
						messageAlert: 'El usuario ha sido eliminado.'
					},
					success: true
				};
			})
			.catch((e) => {
				return {
					data: {
						showConfirm: false,
						spinnerButton: false,
						openAlert: true,
						messageAlert:
							e.response && e.response.data
								? e.response.data.error.message
								: 'Hubo un error al eliminar el usuario, intente nuevamente.'
					},
					success: false
				};
			});
	};
};

export const getManagedUserData = (email, isSearch) => {
	return async (dispatch) => {
		return await Common.find_by_email({ email })
			.then((res) => {
				if (isSearch === true) {
					const arrayResult = Object.keys(res.data).map((i) => res.data[i]);
					return {
						user: res.data.user,
						data: {
							newSearch: true,
							data: arrayResult,
							formErrors: []
						},
						success: true
					};
				} else {
					return {
						user: res.data.user,
						data: {
							showDialog: true,
							is_update: true,
							formErrors: []
						},
						success: true
					};
				}
			})
			.catch((err) => {
				return {
					data: {
						openAlert: true,
						messageAlert:
							err.response && err.response.data
								? err.response.data.error.message
								: 'Ha ocurrido un error al consultar la información, intente nuevamente.'
					},
					success: false
				};
			});
	};
};

export const updateManagedUser = (user) => ({
	type: UPDATE_MANAGED_USER,
	payload: user
});

export const resetManagedUser = () => ({
	type: RESET_MANAGED_USER
});

export function getAsistensiCountries() {
	return async (dispatch) => {
		return await Common.asistensi_countries()
			.then(async (res) => {
				await dispatch({
					type: SAVE_ASISTENSI_COUNTRIES,
					payload: {
						values: {
							data: res?.data?.asistensi_countries
						}
					}
				});
				return {
					success: true,
					spinnerScreen: false
				};
			})
			.catch((e) => {
				return {
					spinnerScreen: false,
					openAlert: true,
					messageAlert: e?.response?.data?.error?.message
						? e.response.data.error.message
						: 'Hubo un error al obtener los países disponibles para asistensi, intente nuevamente.'
				};
			});
	};
}
